import JournalArticles from "@/components/JournalArticles/JournalArticles";
import JournalTags from "@/components/JournalTags/JournalTags";
import MainContainer from "@/components/MainContainer";
import { Authors } from "@/pages/Authors";
import { Categories } from "@/pages/Categories";
import { Error404 } from "@/pages/Error";
import { Experts } from "@/pages/Experts";
import { FaqArticles } from "@/pages/FaqArticles";
import { FaqTags } from "@/pages/FaqTags";
import { Login } from "@/pages/Login";
import { Reviews } from "@/pages/Reviews";
import { SeoInfoList } from "@/pages/SeoInfo";
import { ROUTES } from "@/shared/consts/routes";
import ProfileContainer from "@/widgets/ProfileContainer/ui/ProfileContainer";
import { observer } from "mobx-react-lite";
import { ReactElement } from "react";
import { Route, Routes } from "react-router-dom";

import UserStore from "../../MobxStore/model/UserStore";
import { Private } from "./PrivateRoute";

function Router(): ReactElement {
  const { loading, isAuth } = UserStore;

  if (loading) return <></>;

  return (
    <Routes>
      <Route element={<MainContainer />}>
        {!isAuth ? (
          <Route path={ROUTES.HOME.path} element={<Login />} />
        ) : (
          <Route
            element={
              <Private Component={ProfileContainer} allowed={!!isAuth} />
            }
          >
            <Route path={ROUTES.HOME.path} element={<JournalArticles />} />
            <Route path={ROUTES.GENERAL.AUTHORS.path} element={<Authors />} />
            <Route
              path={ROUTES.GENERAL.SEO_TAGS.path}
              element={<SeoInfoList />}
            />
            <Route path={ROUTES.JOURNAL.TAGS.path} element={<JournalTags />} />
            <Route
              path={ROUTES.GENERAL.CATEGORIES.path}
              element={<Categories />}
            />
            <Route path={ROUTES.FAQ.TAGS.path} element={<FaqTags />} />
            <Route path={ROUTES.FAQ.ARTICLES.path} element={<FaqArticles />} />
            <Route path={ROUTES.GENERAL.REVIEWS.path} element={<Reviews />} />
            <Route path={ROUTES.GENERAL.EXPERT.path} element={<Experts />} />
          </Route>
        )}
        <Route path="*" element={<Error404 />} />
      </Route>
    </Routes>
  );
}

export default observer(Router);
