import { $api, rtkApi } from '@op/api';

import { API_PROMO_INTERNAL_URL, API_PROMO_URL } from '../../Env';
import {
  ISeoInfoAddRequest,
  ISeoInfoDeleteRequest,
  ISeoInfoEditRequest,
  ISeoInfoItem,
  ISeoInfoItemRequest,
  ISeoInfoListResponse,
  ISeoInfoMutationResponse,
} from '../model/types/seoInfo';

const getEndpoint = (apiUrl: string | undefined) => {
  if (!apiUrl) {
    throw new Error('No API endpoint provided');
  }

  return `${apiUrl}/seo-info`;
};

const requestListOptions = (apiUrl: string | undefined) => ({
  url: getEndpoint(apiUrl),
});

const requestItemOptions = (apiUrl: string | undefined, { index }: ISeoInfoItemRequest) => ({
  url: `${getEndpoint(apiUrl)}/by-url`,
  body: { index },
  method: 'post',
});

const requestMutateOptions = (
  apiUrl: string | undefined,
  { token, id, ...params }: ISeoInfoAddRequest | ISeoInfoEditRequest | ISeoInfoDeleteRequest,
) => ({
  url: `${getEndpoint(apiUrl)}/${id ?? ''}`,
  body: params,
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getSeoInfo: build.query<ISeoInfoListResponse, void>({
      query: () => requestListOptions(API_PROMO_URL),
    }),
    getSeoInfoItem: build.query<ISeoInfoItem, ISeoInfoItemRequest>({
      query: (params) => requestItemOptions(API_PROMO_URL, params),
    }),
    addSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoAddRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'post',
      }),
    }),
    editSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoEditRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'put',
      }),
    }),
    deleteSeoInfo: build.mutation<ISeoInfoMutationResponse, ISeoInfoDeleteRequest>({
      query: (params) => ({
        ...requestMutateOptions(API_PROMO_URL, params),
        method: 'delete',
      }),
    }),
  }),
});

export const {
  useGetSeoInfoQuery: useGetSeoInfoList,
  useLazyGetSeoInfoQuery: useLazyGetSeoInfoList,
  useGetSeoInfoItemQuery: useGetSeoInfoItem,
  useAddSeoInfoMutation,
  useEditSeoInfoMutation,
  useDeleteSeoInfoMutation,
} = api;

export const getSeoInfoItem = async (params: ISeoInfoItemRequest) => {
  const { url, body } = requestItemOptions(API_PROMO_INTERNAL_URL, params);
  const seoInfo = await $api.request<ISeoInfoItem>({ url, method: 'post', data: body });
  return seoInfo.data;
};
