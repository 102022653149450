import { TagStore } from "@/app/providers/MobxStore";
import * as ArticleController from "@/controllers/article-controller";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { SelectAuthorController } from "@/features/SelectAuthorController/ui/SelectAuthorController";
import { useUserAuthor } from "@/shared/lib/hooks/useUserAuthor";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { utcOffsetDateString } from "@/shared/lib/utils/utcOffsetDateString";
import { Checkbox, DatePicker, Input, Select } from "@/shared/ui/Form";
import { OutputData } from "@editorjs/editorjs";
import { useGetAuthorList } from "@op/entities";
import classNames from "classnames";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { observer } from "mobx-react-lite";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createReactEditorJS } from "react-editor-js";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { EDITOR_JS_TOOLS } from "../Editorjs";
import { EVoteStatus } from "../Editorjs/tools/Vote/editor";
import ImageUploader from "../ImageUploader";
import {
  defaultValues as dv,
  flagsDictionary,
  flagsKeysTypes,
  urlPattern,
} from "./consts";

const ReactEditorJS = createReactEditorJS();
const cyrillicToTranslit = CyrillicToTranslit();

interface IModalProps extends IModalOpen {
  data: Nullable<ArticleDetail>;
  onSave?: (article: ArticleDetail) => void;
}

function JournalArticlesModalEditor({
  open,
  onSave,
  onClose,
  data,
}: IModalProps) {
  const { userAuthor } = useUserAuthor();
  const { data: authorsData } = useGetAuthorList();
  const authors = authorsData?.payload;

  const defaultValues: typeof dv = useMemo(() => {
    return {
      ...dv,
      author_id: userAuthor?.author_id || authors?.[0]?.author_id,

      tagslug: TagStore.data
        ? TagStore.data[Object.keys(TagStore.data)[0]]?.tagslug
        : undefined,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TagStore.data]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setError,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<ArticleDetail>({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const editorJS = useRef<null | EditorCore>(null);
  const editorContext = useRef({
    setContext: (newState: Object) => {
      editorContext.current = { ...editorContext.current, ...newState };
    },
  });
  const editorTitleRef = useRef<HTMLTextAreaElement>(null);
  const [loading, setLoading] = useState(false);
  const [editorContentChanged, setEditorContentChanged] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { t } = useTranslation();

  const handleInitialize = useCallback((core: EditorCore) => {
    editorJS.current = core;
  }, []);

  const checkIndexIsNotUnique = async (value: string) => {
    if (value === data?.article_index) return false;
    if (await ArticleController.checkArticleIndexExist(value)) {
      setError("article_index", { message: "Указанный URL уже существует" });
      return true;
    }
    return false;
  };

  const prepareBlocksData = async (savedContent: OutputData) => {
    return Promise.all(
      savedContent.blocks.map(async (block, key) => {
        if (
          block.type === "vote" &&
          typeof block.data?.status !== "undefined"
        ) {
          const status = block.data?.status;
          delete block.data?.status;

          const request =
            status === EVoteStatus.isNew
              ? ArticleController.addVoteBlock
              : ArticleController.editVoteBlock;
          const response = await request(block.data);
          if (response) {
            block.data = { ...block.data, vote_id: response.vote_id };
          } else {
            throw new Error();
          }
        }
        if (block.type === "linkTool" && block.data) {
          // remove link block if data is empty\wrong
          if (Object.keys(block.data.meta).length === 0) {
            savedContent.blocks.splice(key, 1);
          }
        }
      })
    );
  };

  const setSeoDescription = async (content: OutputData) => {
    if (!data?.seo_title && !dirtyFields.seo_description) {
      const text = content.blocks.filter(
        (block: { type: string }) => (block.type = "paragraph")
      )[0]?.data?.text;

      if (text) {
        setValue(
          "seo_description",
          text
            .replace(/<(.|\n)*?>/g, "")
            .trim()
            .split(".")
            .map((chunk: string) => chunk.trim())
            .slice(0, 3)
            .join(" ")
        );
        clearErrors("seo_description");
      }
    }
  };

  const handleSave = async (formData: ArticleDetail) => {
    if (editorJS.current && !loading) {
      setLoading(true);

      if (await checkIndexIsNotUnique(formData.article_index)) {
        setLoading(false);
        return;
      }

      let savedContent = await editorJS.current.save();

      try {
        await prepareBlocksData(savedContent);
      } catch (error) {
        setLoading(false);
        return;
      }

      const newData = {
        ...formData,
        content: savedContent as DataProp,
        date: utcOffsetDateString(formData.date),
        created_date_time: utcOffsetDateString(formData.created_date_time),
        modified_date_time: utcOffsetDateString(formData.modified_date_time),
      };

      const response = data
        ? await ArticleController.editArticle(newData)
        : await ArticleController.addArticle(newData);

      if (response) {
        if (typeof response !== "string") {
          // string response if add new article
          newData.article_id = response.id;
          reset(defaultValues);
        }
        onSave && onSave(newData);
        onClose();
      }
      setLoading(false);
    }
  };

  const updateHeight = () => {
    if (editorTitleRef.current) {
      editorTitleRef.current.style.height = "auto";
      editorTitleRef.current.style.height = `${editorTitleRef.current.scrollHeight}px`;
    }
  };

  const handleTitleChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    field: ControllerRenderProps<ArticleDetail, "article_name">
  ) => {
    updateHeight();
    !data?.seo_title &&
      !dirtyFields.seo_title &&
      setValue("seo_title", event.target.value);

    !data?.article_index &&
      !dirtyFields.article_index &&
      setValue(
        "article_index",
        cyrillicToTranslit
          .transform(event.target.value, "-")
          .toLowerCase()
          .slice(0, 300)
      );
    clearErrors(["seo_title", "article_index"]);

    field.onChange(event);
  };

  const handleClose = () => {
    if (editorContentChanged || Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  const handleEditorChange = async () => {
    const savedContent = await editorJS.current?.save();
    setSeoDescription(savedContent);
    setEditorContentChanged(true);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (open && editorTitleRef.current) {
        updateHeight();
        editorTitleRef.current.focus();
      }
    }, 1);
  }, [open]);

  useEffect(() => {
    reset(data || defaultValues);
    if (!open) {
      setConfirmModalOpen(false);
      setEditorContentChanged(false);
    }
  }, [data, defaultValues, open, reset]);

  const dropdownIsInvalid = [
    errors.cover_image,
    errors.article_index,
    errors.seo_title,
    errors.seo_description,
  ].reduce((result, error) => result || !!error, false);

  const { ...editorJsTools } = EDITOR_JS_TOOLS(editorContext.current, {
    uploadImageFolder: "journal",
  });

  return (
    <Modal open={open} onClose={handleClose} canBeExpanded>
      <>
        <div
          className={classNames([
            "editor-header",
            dropdownIsInvalid && "is-invalid",
          ])}
        >
          <div className="editor-wrap">
            <div className="editor-header__rubrics">
              <Controller
                name="tagslug"
                control={control}
                rules={{ required: t("ChooseTag") }}
                render={({ field: { value, onChange } }) => (
                  <Select
                    isInvalid={!!errors.tagslug}
                    className="editor-header__rubric"
                    value={value}
                    setValue={onChange}
                    items={Object.values(TagStore.data).map((tag) => ({
                      value: tag.tagslug,
                      label: tag.tag,
                    }))}
                    searchFunction={({ label, search }) =>
                      label.toLowerCase().includes(search)
                    }
                    searchPlaceholder="Искать рубрику"
                    button={(selectedItem) => (
                      <button
                        className={classNames(
                          "btn btn-outline black scale-up lighten-up size-s",
                          !!errors.tagslug && "red"
                        )}
                      >
                        <i className="btn__text select__btn-text">
                          {selectedItem || "Выбрать рубрику"}
                        </i>
                        <span className="icon icon-cursor-down select__btn-icon" />
                      </button>
                    )}
                    option={(item: string) => (
                      <>
                        <em className="dropdown__text select__text">{item}</em>
                      </>
                    )}
                  />
                )}
              />
              <SelectAuthorController
                name="author_id"
                control={control}
                errors={errors}
              />
            </div>
            <div className="editor-header__dropdown">
              <div className="form editor-header__form">
                <div className="editor-header__row">
                  <div className="editor-header__row-item">
                    <div className="editor-header__image-uploader">
                      <Controller
                        name="cover_image"
                        control={control}
                        rules={{ required: t("UploadCover") }}
                        render={({ field: { value, onChange } }) => (
                          <ImageUploader
                            value={value}
                            folder="journal"
                            onChange={onChange}
                            isInvalid={!!errors.cover_image}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="editor-header__row-item">
                    <div className="editor-header__params">
                      <span className="form__label">
                        {t("ArticleParameters")}
                      </span>
                      <div className="form__row">
                        {(Object.keys(flagsDictionary) as flagsKeysTypes[]).map(
                          (key) => (
                            <Controller
                              name={key}
                              key={key}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Checkbox
                                  label={t(flagsDictionary[key])}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                          )
                        )}
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__item">
                        <span className="form__label">{t("ArticleDate")}</span>
                        <div className="input-block">
                          <Controller
                            name="date"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <DatePicker value={value} onChange={onChange} />
                            )}
                          />
                        </div>
                      </div>
                      <div className="form__item">
                        <span className="form__label">
                          {t("ArticleUpdateDate")}
                        </span>
                        <div className="input-block">
                          <Controller
                            name="modified_date_time"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <DatePicker value={value} onChange={onChange} />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__item">
                        <span className="form__label">{t("URL")}</span>
                        <Input
                          {...register("article_index", {
                            required: t("PasteURL"),
                            pattern: {
                              value: urlPattern,
                              message: t("OnlyLatin"),
                            },
                          })}
                          disabled={!!data}
                          isInvalid={!!errors.article_index}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__item">
                    <span className="form__label">{t("SEO")}</span>
                    <Input
                      {...register("seo_title", {
                        ...requiredValidateWhiteSpaces(t("PasteSEO")),
                      })}
                      isInvalid={!!errors.seo_title}
                    />
                  </div>
                  <div className="form__item">
                    <span className="form__label">{t("SEODescription")}</span>
                    <Input
                      {...register("seo_description", {
                        ...requiredValidateWhiteSpaces(
                          t("PasteSEODescription")
                        ),
                      })}
                      isInvalid={!!errors.seo_description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="editor-body">
          <div className="editor-wrap">
            <Controller
              name="article_name"
              control={control}
              rules={{
                ...requiredValidateWhiteSpaces(t("PasteTitle")),
                maxLength: {
                  value: 144,
                  message: t("TitleMaxLength"),
                },
              }}
              render={({ field }) => (
                <textarea
                  className="editor-title"
                  placeholder={t("NewTitle")}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      editorJS.current?._editorJS?.focus();
                      editorJS.current?._editorJS?.toolbar.open();
                    }
                  }}
                  onChange={(e) => handleTitleChange(e, field)}
                  onBlur={field.onBlur}
                  ref={editorTitleRef}
                />
              )}
            />
            <ReactEditorJS
              onInitialize={handleInitialize}
              tools={{ ...editorJsTools }}
              defaultValue={data?.content}
              onChange={handleEditorChange}
            />
          </div>
        </div>
        <div className="editor-footer">
          <div className="editor-wrap">
            <div className="editor-footer__btns">
              <div className="editor-footer__btns-item">
                <button
                  className={classNames(
                    "btn btn-fond scale-up lighten-up black",
                    Object.keys(errors).length > 0 && "btn-disabled"
                  )}
                  onClick={handleSubmit(handleSave)}
                >
                  <i className="btn__text">
                    {loading ? t("Saving") : t("Publish")}
                  </i>
                </button>
              </div>
              {errors && (
                <div className="editor-footer__btns-item">
                  {Object.entries(errors).map(([key, error]) => (
                    <p key={key} className="editor-footer__error">
                      {error.message}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
}

export default observer(JournalArticlesModalEditor);
