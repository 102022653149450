import { TagStore, UserStore } from "@/app/providers/MobxStore";
import { EDITOR_JS_TOOLS } from "@/components/Editorjs";
import ImageUploader from "@/components/ImageUploader";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { SelectAuthorController } from "@/features/SelectAuthorController";
import { SelectFaqTagController } from "@/features/SelectFaqTagController";
import { prepareBlocksData } from "@/pages/FaqArticles/lib/utils/prepareData";
import { notify } from "@/shared/lib/utils/notify";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { utcOffsetDateString } from "@/shared/lib/utils/utcOffsetDateString";
import { Checkbox, DatePicker, Input, Textarea } from "@/shared/ui/Form";
import { OutputData } from "@editorjs/editorjs";
import {
  IFaqArticle,
  IFaqArticleDetail,
  useAddFaqArticleMutation,
  useEditFaqArticleMutation,
} from "@op/entities";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import classNames from "classnames";
import CyrillicToTranslit from "cyrillic-to-translit-js";
import { observer } from "mobx-react-lite";
import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { createReactEditorJS } from "react-editor-js";
import { Controller, ControllerRenderProps, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  defaultValues as dv,
  flagsDictionary,
  flagsKeysTypes,
  urlPattern,
} from "../model/consts/faqArticleModalEdtiorConsts";

const ReactEditorJS = createReactEditorJS();
const cyrillicToTranslit = CyrillicToTranslit();

interface IModalProps extends IModalOpen {
  data?: IFaqArticleDetail;
  onSave?: () => void;
}

function FaqArticlesModalEditor({ open, onSave, onClose, data }: IModalProps) {
  const defaultValues: typeof dv = useMemo(() => {
    return {
      ...dv,
      author_id: 0,
      //    UserStore.author
      //     ? UserStore.author.author_id
      //     : AuthorStore.data[0]?.author_id,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [TagStore.data]);

  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    clearErrors,
    formState: { errors, dirtyFields },
  } = useForm<IFaqArticleDetail>({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const editorJS = useRef<null | EditorCore>(null);
  const editorContext = useRef({
    setContext: (newState: Object) => {
      editorContext.current = {
        ...editorContext.current,
        ...newState,
      };
    },
  });
  const editorTitleRef = useRef<HTMLTextAreaElement>(null);
  const [editorContentChanged, setEditorContentChanged] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  const [addFaqArticle, { isLoading: isAddFaqArticleLoading }] =
    useAddFaqArticleMutation();
  const [editFaqArticle, { isLoading: isEditFaqArticleLoading }] =
    useEditFaqArticleMutation();

  const { t } = useTranslation();

  const handleInitialize = useCallback((core: EditorCore) => {
    editorJS.current = core;
  }, []);

  const setDescription = async (content: OutputData) => {
    if (!data?.seo_title && !dirtyFields.seo_description) {
      const text = content.blocks.filter(
        (block: { type: string }) => (block.type = "paragraph")
      )[0]?.data?.text;

      if (text) {
        const value = text
          .replace(/<(.|\n)*?>/g, "")
          .trim()
          .split(".")
          .map((chunk: string) => chunk.trim())
          .slice(0, 3)
          .join(" ");

        setValue("description", value);
        setValue("seo_description", value);
        clearErrors("description");
        clearErrors("seo_description");
      }
    }
  };

  const handleSave = async (formData: IFaqArticle) => {
    if (editorJS.current) {
      let savedContent = await editorJS.current.save();

      try {
        await prepareBlocksData(savedContent);
      } catch (error) {
        notify(t("AnErrorOcurred"));
        return;
      }

      const newData = {
        ...formData,
        tag_id: !!data ? data.tag_id : formData.tag_id,
        content: savedContent as DataProp,
        date: utcOffsetDateString(formData.publication_date_time_utc),
        created_date_time: utcOffsetDateString(formData.created_date_time_utc),
        modified_date_time: utcOffsetDateString(
          formData.modified_date_time_utc
        ),
        token: UserStore.token!,
      };

      const response = data
        ? await editFaqArticle(newData)
        : await addFaqArticle(newData);

      if ("error" in response) {
        notify((response.error as FetchBaseQueryError).data);
      }
      if ("data" in response) {
        if (typeof response !== "string") {
          reset(defaultValues);
        }
        onSave?.();
        onClose();
      }
    }
  };

  const updateHeight = () => {
    if (editorTitleRef.current) {
      editorTitleRef.current.style.height = "auto";
      editorTitleRef.current.style.height = `${editorTitleRef.current.scrollHeight}px`;
    }
  };

  const handleTitleChange = (
    event: ChangeEvent<HTMLTextAreaElement>,
    field: ControllerRenderProps<IFaqArticleDetail, "title">
  ) => {
    updateHeight();
    !data?.seo_title &&
      !dirtyFields.seo_title &&
      setValue("seo_title", event.target.value);

    !data?.index &&
      !dirtyFields.index &&
      setValue(
        "index",
        cyrillicToTranslit
          .transform(event.target.value, "-")
          .toLowerCase()
          .slice(0, 300)
      );
    clearErrors(["seo_title", "index"]);

    field.onChange(event);
  };

  const handleClose = () => {
    if (data?.is_enabled === false) {
      onClose();
      return;
    }
    const hasUnsavedChanges =
      editorContentChanged || Object.keys(dirtyFields).length > 0;
    hasUnsavedChanges ? setConfirmModalOpen(true) : onClose();
  };

  const handleEditorChange = async () => {
    const savedContent = await editorJS.current?.save();
    setDescription(savedContent);
    setEditorContentChanged(true);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (open && editorTitleRef.current) {
        updateHeight();
        editorTitleRef.current.focus();
      }
    }, 1);
  }, [open]);

  useEffect(() => {
    reset(data || defaultValues);
    if (!open) {
      setConfirmModalOpen(false);
      setEditorContentChanged(false);
    }
  }, [data, defaultValues, open, reset]);

  const dropdownIsInvalid = [
    errors.image,
    errors.index,
    errors.seo_title,
    errors.seo_description,
  ].reduce((result, error) => result || !!error, false);

  const {
    image: imageS3,
    header,
    paragraph,
    list,
    incut,
    raw,
    table,
    service,
  } = EDITOR_JS_TOOLS(editorContext.current, { uploadImageFolder: "faq" });

  return (
    <Modal open={open} onClose={handleClose} canBeExpanded>
      <>
        <div
          className={classNames([
            "editor-header",
            dropdownIsInvalid && "is-invalid",
          ])}
        >
          <div className="editor-wrap">
            <div className="editor-header__rubrics">
              <SelectFaqTagController
                name="tag_id"
                disabled={!!data}
                control={control}
                errors={errors}
              />
              <SelectAuthorController
                name="author_id"
                control={control}
                errors={errors}
              />
            </div>
            <div className="editor-header__dropdown">
              <div className="form editor-header__form">
                <div className="editor-header__row">
                  <div className="editor-header__row-item">
                    <div className="editor-header__image-uploader">
                      <Controller
                        name="image"
                        control={control}
                        rules={{ required: t("UploadCover") }}
                        render={({ field: { value, onChange } }) => (
                          <ImageUploader
                            value={value}
                            folder="faq"
                            onChange={onChange}
                            isInvalid={!!errors.image}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="editor-header__row-item">
                    <div className="editor-header__params">
                      <span className="form__label">
                        {t("ArticleParameters")}
                      </span>
                      <div className="form__row">
                        {(["is_draft", "is_popular"] as flagsKeysTypes[]).map(
                          (key) => (
                            <Controller
                              name={key}
                              key={key}
                              control={control}
                              render={({ field: { value, onChange } }) => (
                                <Checkbox
                                  label={t(flagsDictionary[key])}
                                  value={value}
                                  onChange={onChange}
                                />
                              )}
                            />
                          )
                        )}
                      </div>
                      <div className="form__row">
                        {(
                          [
                            "is_protect_trademark",
                            "is_register_product",
                          ] as flagsKeysTypes[]
                        ).map((key) => (
                          <Controller
                            name={key}
                            key={key}
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <Checkbox
                                label={t(flagsDictionary[key])}
                                value={value}
                                onChange={onChange}
                              />
                            )}
                          />
                        ))}
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__item">
                        <span className="form__label">{t("ArticleDate")}</span>
                        <div className="input-block">
                          <Controller
                            name="publication_date_time_utc"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <DatePicker value={value} onChange={onChange} />
                            )}
                          />
                        </div>
                      </div>
                      <div className="form__item">
                        <span className="form__label">
                          {t("ArticleUpdateDate")}
                        </span>
                        <div className="input-block">
                          <Controller
                            name="modified_date_time_utc"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                              <DatePicker value={value} onChange={onChange} />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form__row">
                      <div className="form__item">
                        <span className="form__label">{t("URL")}</span>
                        <Input
                          {...register("index", {
                            required: t("PasteURL"),
                            pattern: {
                              value: urlPattern,
                              message: t("OnlyLatin"),
                            },
                          })}
                          disabled={!!data}
                          isInvalid={!!errors.index}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__item form__item--full">
                    <span className="form__label">{t("Description")}</span>
                    <Textarea
                      rows={5}
                      {...register("description")}
                      isInvalid={!!errors.description}
                    />
                  </div>
                </div>
                <div className="form__row">
                  <div className="form__item">
                    <span className="form__label">{t("SEO")}</span>
                    <Input
                      {...register("seo_title", {
                        ...requiredValidateWhiteSpaces(t("PasteSEO")),
                      })}
                      isInvalid={!!errors.seo_title}
                    />
                  </div>
                  <div className="form__item">
                    <span className="form__label">{t("SEODescription")}</span>
                    <Input
                      {...register("seo_description", {
                        ...requiredValidateWhiteSpaces(
                          t("PasteSEODescription")
                        ),
                      })}
                      isInvalid={!!errors.seo_description}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="editor-body">
          <div className="editor-wrap">
            <Controller
              name="title"
              control={control}
              rules={{
                ...requiredValidateWhiteSpaces(t("PasteTitle")),
                maxLength: {
                  value: 144,
                  message: t("TitleMaxLength"),
                },
              }}
              render={({ field }) => (
                <textarea
                  className="editor-title"
                  placeholder={t("NewTitle")}
                  value={field.value}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                      editorJS.current?._editorJS?.focus();
                      editorJS.current?._editorJS?.toolbar.open();
                    }
                  }}
                  onChange={(e) => handleTitleChange(e, field)}
                  onBlur={field.onBlur}
                  ref={editorTitleRef}
                />
              )}
            />
            <ReactEditorJS
              onInitialize={handleInitialize}
              tools={{
                image: imageS3,
                header,
                paragraph,
                list,
                incut,
                raw,
                table,
                service,
              }}
              defaultValue={JSON.parse(data?.content ?? "{}")}
              onChange={handleEditorChange}
            />
          </div>
        </div>
        <div className="editor-footer">
          <div className="editor-wrap">
            <div className="editor-footer__btns">
              <div className="editor-footer__btns-item">
                {data?.is_enabled !== false && (
                  <button
                    className={classNames(
                      "btn btn-fond scale-up lighten-up black",
                      Object.keys(errors).length > 0 && "btn-disabled"
                    )}
                    onClick={handleSubmit(handleSave)}
                  >
                    <i className="btn__text">
                      {isAddFaqArticleLoading || isEditFaqArticleLoading
                        ? t("Saving")
                        : t("Publish")}
                    </i>
                  </button>
                )}
              </div>
              {errors && (
                <div className="editor-footer__btns-item">
                  {Object.entries(errors).map(([key, error]) => (
                    <p key={key} className="editor-footer__error">
                      {error?.message}
                    </p>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
}

export default observer(FaqArticlesModalEditor);
