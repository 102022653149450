import { StateSchema } from "@/app/providers/StoreProvider";

export const getJournalArticlesModalEditorOpen = (state: StateSchema) =>
  state.ui.journalArticlesModalEditorOpen;
export const getFaqArticlesModalEditorOpen = (state: StateSchema) =>
  state.ui.faqArticlesModalEditorOpen;
export const getAuthorCreatorOpen = (state: StateSchema) =>
  state.ui.authorCreatorOpen;
export const getSeoTagCreatorOpen = (state: StateSchema) =>
  state.ui.seoInfoCreatorOpen;
export const getJournalTagCreatorOpen = (state: StateSchema) =>
  state.ui.journalTagCreatorOpen;
export const getFaqTagsCreatorOpen = (state: StateSchema) =>
  state.ui.faqTagsCreatorOpen;
export const getReviewCreatorOpen = (state: StateSchema) =>
  state.ui.reviewCreatorOpen;
export const getExpertCreatorOpen = (state: StateSchema) =>
  state.ui.expertCreatorOpen;
export const getSeoInfoCreatorOpen = (state: StateSchema) =>
  state.ui.seoInfoCreatorOpen;
