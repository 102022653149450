import UserStore from "@/app/providers/MobxStore/model/UserStore";
import { uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { ProfileAsideGroup } from "./ProfileAsideGroup";

const ProfileAside = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    UserStore.logout();
    navigate(ROUTES.HOME.path);
  };

  const handleJournalArticlesModalEditorOpen = useCallback(() => {
    dispatch(uiActions.setJournalArticlesModalEditorOpen(true));
  }, [dispatch]);

  const handleFaqArticlesModalEditorOpen = useCallback(() => {
    dispatch(uiActions.setFaqArticlesModalEditorOpen(true));
  }, [dispatch]);

  const handleAuthorCreatorOpen = useCallback(() => {
    dispatch(uiActions.setAuthorCreatorOpen(true));
  }, [dispatch]);

  const handleSeoInfoCreatorOpen = useCallback(() => {
    dispatch(uiActions.setSeoInfoCreatorOpen(true));
  }, [dispatch]);

  const handleReviewCreatorOpen = useCallback(() => {
    dispatch(uiActions.setReviewCreatorOpen(true));
  }, [dispatch]);

  const handleExpertCreatorOpen = useCallback(() => {
    dispatch(uiActions.setExpertCreatorOpen(true));
  }, [dispatch]);

  const handleJournalTagCreatorOpen = useCallback(() => {
    dispatch(uiActions.setJournalTagCreatorOpen(true));
  }, [dispatch]);

  const handleFaqTagCreatorOpen = useCallback(() => {
    dispatch(uiActions.setFaqTagsCreatorOpen(true));
  }, [dispatch]);

  const generalMenuItems = useMemo(
    () => [
      {
        name: t("Categories"),
        link: ROUTES.GENERAL.CATEGORIES.path,
      },
      {
        name: t("AuthorsList"),
        link: ROUTES.GENERAL.AUTHORS.path,
        onPlusClick: handleAuthorCreatorOpen,
      },
      {
        name: t("SeoInfoList"),
        link: ROUTES.GENERAL.SEO_TAGS.path,
        onPlusClick: handleSeoInfoCreatorOpen,
      },
      {
        name: t("Reviews"),
        link: ROUTES.GENERAL.REVIEWS.path,
        onPlusClick: handleReviewCreatorOpen,
      },
      {
        name: t("Experts"),
        link: ROUTES.GENERAL.EXPERT.path,
        onPlusClick: handleExpertCreatorOpen,
      },
    ],
    [
      t,
      handleAuthorCreatorOpen,
      handleSeoInfoCreatorOpen,
      handleReviewCreatorOpen,
      handleExpertCreatorOpen,
    ]
  );

  const journalMenuItems = useMemo(
    () => [
      {
        name: t("Articles"),
        link: ROUTES.HOME.path,
        onPlusClick: handleJournalArticlesModalEditorOpen,
      },
      {
        name: t("Tags"),
        link: ROUTES.JOURNAL.TAGS.path,
        onPlusClick: handleJournalTagCreatorOpen,
      },
    ],
    [t, handleJournalArticlesModalEditorOpen, handleJournalTagCreatorOpen]
  );

  const faqMenuItems = useMemo(
    () => [
      {
        name: t("Articles"),
        link: ROUTES.FAQ.ARTICLES.path,
        onPlusClick: handleFaqArticlesModalEditorOpen,
      },
      {
        name: t("Tags"),
        link: ROUTES.FAQ.TAGS.path,
        onPlusClick: handleFaqTagCreatorOpen,
      },
    ],
    [t, handleFaqArticlesModalEditorOpen, handleFaqTagCreatorOpen]
  );

  return (
    <>
      <div className="profile-page__aside aside">
        <ProfileAsideGroup title={t("General")} items={generalMenuItems} />
        <ProfileAsideGroup title={t("Journal")} items={journalMenuItems} />
        <ProfileAsideGroup title={t("Faq")} items={faqMenuItems} />

        <div className="profile-page__aside-menu-bottom">
          <ul className="aside-menu article-page__aside-menu">
            <li className="aside-menu__item">
              <button
                onClick={handleLogout}
                className="aside-menu__link aside-menu__link_static"
              >
                <span className="aside-menu__item-name">{t("Exit")}</span>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default observer(ProfileAside);
