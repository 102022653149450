import { rtkApi } from '@op/api';

import { API_PROMO_URL } from '../../Env';
import { IExpertsResponse } from '../model/types/experts';

const expertsApi = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getAllExperts: build.query<IExpertsResponse, void>({
      query: () => ({
        url: `${API_PROMO_URL}/experts`,
      }),
    }),
  }),
});

export const useGetAllExperts = expertsApi.useGetAllExpertsQuery;
