// https://my.bee.onlinepatent.ru/core-journal/swagger - api description
import { API_JOURNAL_URL, CDN_URL } from "@op/entities";

export const ENDPOINTS = {
  API_ROOT: API_JOURNAL_URL,
  S3_URL: CDN_URL,
  AUTH: {
    GET_USER: "/auth/info",
    GET_TOKEN: "/auth/login",
  },
  ARTICLE: {
    PATH: "/articles/view",
    ADD: "/articles/add",
    EDIT: "/articles/edit",
    DELETE: "/articles/delete",
    FILTER: {
      PATH: "/articles/filter",
      BY_TAG_ID: "/articles/id/", //:id
      BY_TAG_INDEX: "/articles/tag/name/", //:taguslug
      TOP: "/articles/top/view",
      LATEST_NEWS: "/articles/latest_news",
      MAIN: "/articles/main",
      IMPORTANT: "/articles/important",
    },
    DETAILS: {
      PATH: "/articles/view/",
      PATH_ID: "/articles/view/id/",
      ADD_LIKE: "/articles/likes/add/", //:article_id
      VIEW_COUNT: "/articles/view_counts/add/", //:article_id
    },
  },
  VOTES: {
    ADD: "/votes/add",
    GET: "/votes/view/", //:vote_id
    EDIT: "/votes/edit",
    ADD_COUNT: "/votes/vote/", //<:vote_id>_<:answer_id>
  },
  EDITOR: {
    IMAGE_UPLOAD: "/image/upload",
    FETCH_URL: "/editor/fetchUrl",
  },
  TAGS: {
    PATH: "/tags/view",
    ADD: "/tags/add",
    EDIT: "/tags/edit",
    DELETE: "/tags/delete",
  },
};
