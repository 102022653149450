import { $api, rtkApi } from '@op/api';
import { AxiosResponse } from 'axios';

import { API_JOURNAL_URL } from '../../Env';
import {
  IAuthorAddRequest,
  IAuthorDeleteRequest,
  IAuthorEditRequest,
  IAuthorGetRequest,
  IAuthorListGetResponse,
  IAuthorStatistics,
  IAuthorStatisticsGetRequest,
} from '../model/types/author';

const requestListOptions = () => ({
  url: `${API_JOURNAL_URL}/authors/view`,
  method: 'post',
});

const requestStatisticsOptions = (params: IAuthorGetRequest) => ({
  url: `${API_JOURNAL_URL}/authors/stat/${params.author_id}`,
  method: 'post',
});

const mutationRequestOptions = (
  { token, ...params }: IAuthorAddRequest | IAuthorEditRequest | IAuthorDeleteRequest,
  method: 'add' | 'edit' | 'delete',
) => ({
  url: `${API_JOURNAL_URL}/authors/${method}`,
  body: params,
  method: 'post',
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const api = rtkApi.injectEndpoints({
  endpoints: (build) => ({
    getAuthorList: build.query<IAuthorListGetResponse, void>({
      query: requestListOptions,
    }),
    getAuthorStatistics: build.query<IAuthorStatistics, IAuthorStatisticsGetRequest>({
      query: requestStatisticsOptions,
    }),
    addAuthor: build.mutation<number, IAuthorAddRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'add'),
      }),
    }),
    editAuthor: build.mutation<number, IAuthorEditRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'edit'),
      }),
    }),
    deleteAuthor: build.mutation<number, IAuthorDeleteRequest>({
      query: (params) => ({
        ...mutationRequestOptions(params, 'delete'),
      }),
    }),
  }),
});

export const {
  useGetAuthorListQuery: useGetAuthorList,
  useLazyGetAuthorListQuery: useLazyGetAuthorList,
  useAddAuthorMutation,
  useEditAuthorMutation,
  useDeleteAuthorMutation,
} = api;

export const getAuthorList = (): Promise<AxiosResponse<IAuthorListGetResponse>> => $api.request(requestListOptions());

export const getAuthorStatistics = (params: IAuthorStatisticsGetRequest): Promise<AxiosResponse<IAuthorStatistics>> =>
  $api.request(requestStatisticsOptions(params));
