export const ROUTES = {
  HOME: { label: "HomePage", path: "/" },
  GENERAL: {
    AUTHORS: { label: "AuthorsList", path: "/authors" },
    SEO_TAGS: { label: "SeoTagsList", path: "/seo-tags" },
    REVIEWS: { label: "ReviewsList", path: "/reviews" },
    CATEGORIES: { label: "CategoriesList", path: "/categories" },
    EXPERT: { label: "ExpertList", path: "/experts" },
  },
  JOURNAL: {
    ARTICLES: { label: "Articles", path: "/journal/articles" },
    TAGS: { label: "Tags", path: "/journal/tags" },
  },
  FAQ: {
    ARTICLES: { label: "Articles", path: "/faq/articles" },
    TAGS: { label: "Tags", path: "/faq/tags" },
  },
};
