import { Router } from "@/app/providers/Router";
import { Error500 } from "@/pages/Error";
import { getConfig } from "@/shared/lib/utils/getConfig";
import moment from "moment";
import "moment/locale/ru";
import { ReactElement, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import TagStore from "./providers/MobxStore/model/TagStore";
import UserStore from "./providers/MobxStore/model/UserStore";
import { StoreProvider } from "./providers/StoreProvider";

moment.locale("ru");

function App(): ReactElement {
  const [externalStylesLoading, setExternalStylesLoading] = useState(true);
  const { REACT_APP_ADMIN_VERSION, REACT_APP_ASSETS_URL } = getConfig();

  useEffect(() => {
    const init = async () => {
      TagStore.fetchTags();
      UserStore.init();
    };
    init();

    REACT_APP_ADMIN_VERSION && console.info(REACT_APP_ADMIN_VERSION);
  }, [REACT_APP_ADMIN_VERSION]);

  useEffect(() => {
    const externalStyles = ["general.css", "magazine.css"];
    const stylesPromises = externalStyles.map((style) => {
      return new Promise((res, rej) => {
        const link = document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", `${REACT_APP_ASSETS_URL}/styles/${style}`);
        document.head.appendChild(link);
        link.onload = res;
        link.onerror = () => {
          console.info(
            `Не удалось загрузить ${style}. Проверьте работоспособность ${REACT_APP_ASSETS_URL}`
          );
        };
      });
    });

    Promise.all(stylesPromises).then(() => {
      setExternalStylesLoading(false);
    });
  }, [REACT_APP_ASSETS_URL]);

  if (externalStylesLoading) return <></>;

  return (
    <ErrorBoundary FallbackComponent={Error500}>
      <StoreProvider>
        <BrowserRouter>
          <ToastContainer />
          <Router />
        </BrowserRouter>
      </StoreProvider>
    </ErrorBoundary>
  );
}

export default App;
