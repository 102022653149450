import { Author } from "@/shared/ui/Author";
import { useGetAuthorList } from "@op/entities";
import React, { useMemo } from "react";

interface ICardArticleInfo {
  title: string;
  authorId: number;
  articleIndex: string;
}

const CardArticleInfo = ({
  title,
  authorId,
  articleIndex,
}: ICardArticleInfo) => {
  const { data: authorsData } = useGetAuthorList();
  const authors = authorsData?.payload;

  const author = useMemo(
    () => authors?.find((author) => author.author_id === authorId),
    [authors, authorId]
  );

  return (
    <>
      <p className="profile-article__title">{title}</p>
      <div className="profile-article__info">
        <div className="profile-article__info-item">
          {author && (
            <Author
              name={author.name}
              initials={author.initials}
              avatar={author.avatar}
            />
          )}
        </div>
        <div className="profile-article__info-item">
          <span className="profile-article__url">{articleIndex}</span>
        </div>
      </div>
    </>
  );
};

export default CardArticleInfo;
