import { TagStore } from "@/app/providers/MobxStore";
import JournalArticlesModalEditor from "@/components/JournalArticles/JournalArticlesModalEditor";
import JournalTagsModal from "@/components/JournalTags/JournalTagsModal";
import ProfileAside from "@/components/ProfileAside/ProfileAside";
import ProfileHeader from "@/components/ProfileHeader";
import {
  getAuthorCreatorOpen,
  getJournalArticlesModalEditorOpen,
  getJournalTagCreatorOpen,
} from "@/features/UI";
import { uiActions } from "@/features/UI";
import { ROUTES } from "@/shared/consts/routes";
import { useAppDispatch } from "@/shared/lib/hooks/useAppDispatch";
import { AuthorModal } from "@/widgets/AuthorModal";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { ExpertsCreator } from "./ExpertsCreator";
import { FaqArticlesCreator } from "./FaqArticlesCreator";
import { FaqTagsCreator } from "./FaqTagsCreator";
import { ReviewsCreator } from "./ReviewsCreator";
import { SeoInfoCreator } from "./SeoInfoCreator";

const ProfileContainer = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const journalArticlesModalEditorOpen = useSelector(
    getJournalArticlesModalEditorOpen
  );
  const authorCreatorOpened = useSelector(getAuthorCreatorOpen);
  const journalTagCreatorOpened = useSelector(getJournalTagCreatorOpen);

  const handleJouralArticleCreatorModalOnSave = useCallback(() => {
    navigate(ROUTES.HOME.path);
  }, [navigate]);

  const handleJournalTagCreatorOnSave = useCallback(() => {
    TagStore.fetchTags();
    navigate(ROUTES.JOURNAL.TAGS.path);
  }, [navigate]);

  const handleAuthorCreatorOnSave = useCallback(() => {
    // AuthorStore.fetchAuthors();
    navigate(ROUTES.GENERAL.AUTHORS.path);
  }, [navigate]);

  const handleJournalArticlesModalEditorClose = useCallback(() => {
    dispatch(uiActions.setJournalArticlesModalEditorOpen(false));
  }, [dispatch]);

  const handleAuthorCreatorClose = useCallback(() => {
    dispatch(uiActions.setAuthorCreatorOpen(false));
  }, [dispatch]);

  const handleJournalTagCreatorClose = useCallback(() => {
    dispatch(uiActions.setJournalTagCreatorOpen(false));
  }, [dispatch]);

  return (
    <>
      <div className="profile-section">
        <div className="wrap">
          <div className="profile profile-page">
            <ProfileHeader />
            <div className="profile-page__columns">
              <ProfileAside />
              <div className="profile-page__center">
                <Outlet />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AuthorModal
        open={authorCreatorOpened}
        onClose={handleAuthorCreatorClose}
        data={null}
        onSave={handleAuthorCreatorOnSave}
      />
      <JournalArticlesModalEditor
        open={journalArticlesModalEditorOpen}
        onClose={handleJournalArticlesModalEditorClose}
        data={null}
        onSave={handleJouralArticleCreatorModalOnSave}
      />
      <JournalTagsModal
        open={journalTagCreatorOpened}
        onClose={handleJournalTagCreatorClose}
        data={null}
        onSave={handleJournalTagCreatorOnSave}
      />
      <SeoInfoCreator />
      <ReviewsCreator />
      <ExpertsCreator />
      <FaqArticlesCreator />
      <FaqTagsCreator />
    </>
  );
};

export default observer(ProfileContainer);
