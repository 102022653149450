import { ConfirmModal } from "@/features/Modal";
import { PAGINATION_SIZE } from "@/shared/consts/common";
import { useModalConfirm } from "@/shared/lib/hooks/useModalComfirm";
import { useModalOpen } from "@/shared/lib/hooks/useModalOpen";
import { pagination } from "@/shared/lib/utils/pagination";
import Input from "@/shared/ui/Form/ui/Input";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";

import TagStore from "../../app/providers/MobxStore/model/TagStore";
import ItemCard from "../ItemCard";
import PaginationArrows from "../PaginationArrows";
import JournalTagsModal from "./JournalTagsModal";

function JournalTags() {
  const { t } = useTranslation();
  const [modalEditorOpened, setModalEditorOpened] = useModalOpen();
  const [modalEditorData, setModalEditorData] =
    useState<Nullable<TagResponse>>(null);
  const [modalConfirm, setModalConfirm] = useModalConfirm();

  const [searchString, setSearchString] = useState("");
  const [currentPaginationPosition, setCurrentPaginationPosition] = useState(0);

  const loading = TagStore.loading;
  const rubrics = TagStore.data;

  const handlePagination = (next?: boolean) => {
    setCurrentPaginationPosition(
      pagination(
        currentPaginationPosition,
        PAGINATION_SIZE,
        Object.keys(rubrics).length,
        !!next
      )
    );
  };

  const handleConfirm = (id?: number) => {
    id
      ? setModalConfirm({ isOpen: true, id: id })
      : setModalConfirm({
          isOpen: false,
          id: null,
        });
  };

  const handleDelete = () => {
    if (modalConfirm.id) {
      TagStore.deleteTag(modalConfirm.id);
      setModalConfirm({ isOpen: false, id: null });
    }
  };

  const handleEdit = (tagslug: TagResponse["tagslug"]) => {
    const itemToEdit = rubrics[tagslug];
    if (itemToEdit) {
      setModalEditorOpened(true);
      setModalEditorData(itemToEdit);
    }
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchString(event.target.value.toLowerCase());
    setCurrentPaginationPosition(0);
  };

  const filteredList = Object.values(rubrics).filter((rubric) =>
    rubric.tag.toLowerCase().includes(searchString)
  );

  const handleAdd = () => {
    setModalEditorOpened(true);
    setModalEditorData(null);
  };

  const handleModalEditorOnSave = async () => {
    const { pageYOffset } = window;

    window.scrollTo(0, 0);
    await TagStore.fetchTags();
    setTimeout(() => {
      window.scrollTo(0, pageYOffset);
    }, 1);
  };

  return (
    <>
      <div className="profile__title-block">
        <strong className="profile__title">{t("TagsList")}</strong>
        <button
          className="btn btn-fond blue scale-up lighten-up size-s profile__title-btn"
          onClick={handleAdd}
        >
          <span className="icon icon-plus btn__icon" />
          <i className="btn__text">{t("TagAdd")}</i>
        </button>
      </div>
      <div className="profile__control">
        <div className="profile__control-item">
          <div className="search-input profile__search">
            <Input
              placeholder={t("SearchByTags")}
              className="input_stroke profile__search-input"
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <div className="profile__control-item">
          <div className="profile__control-block">
            <PaginationArrows
              onClick={handlePagination}
              currentPosition={currentPaginationPosition}
              paginationSize={PAGINATION_SIZE}
              catalogLength={Object.values(rubrics).length}
            />
          </div>
        </div>
      </div>
      <div className="profile__body profile-rubrics">
        {loading && <div>{t("Loading")}...</div>}

        {filteredList
          .slice(
            currentPaginationPosition,
            currentPaginationPosition + PAGINATION_SIZE
          )
          .map((rubric) => (
            <ItemCard
              key={rubric.tag_id}
              className="card-rubric"
              cardId={rubric.tag_id}
              cardLink={rubric.tag}
              onDelete={handleConfirm}
              onEdit={() => handleEdit(rubric.tagslug)}
              cells={[
                <React.Fragment key={1}>{rubric.tag}</React.Fragment>,
                <React.Fragment key={1}>
                  {rubric.is_enabled ? t("TagShow") : t("TagHidden")}
                </React.Fragment>,
              ]}
            />
          ))}

        <div className="profile__pagination-bottom">
          <PaginationArrows
            onClick={handlePagination}
            currentPosition={currentPaginationPosition}
            paginationSize={PAGINATION_SIZE}
            catalogLength={filteredList.length}
          />
        </div>
      </div>

      <JournalTagsModal
        open={modalEditorOpened}
        onClose={() => setModalEditorOpened(false)}
        data={modalEditorData}
        onSave={handleModalEditorOnSave}
      />
      <ConfirmModal
        text={t("TagDeleteConfirm")}
        modalOpen={modalConfirm.isOpen}
        setModalOpen={handleConfirm}
        onConfirm={handleDelete}
      />
    </>
  );
}

export default observer(JournalTags);
