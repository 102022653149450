import ImageUploader from "@/components/ImageUploader";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import Input from "@/shared/ui/Form/ui/Input";
import { IAuthor } from "@op/entities";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

export type TFormFields = Omit<IAuthor, "author_id">;

interface IAuthorFormProps {
  data: Nullable<IAuthor>;
  isLoading: boolean;
  onSubmit: (formData: TFormFields) => Promise<void>;
  onDirtyChange: (isDirty: boolean) => void;
}

export const defaultValues: TFormFields = {
  avatar: "",
  company: "",
  description: "",
  initials: "",
  name: "",
  user_id: null,
};

export const AuthorModalForm = ({
  data,
  isLoading,
  onSubmit,
  onDirtyChange,
}: IAuthorFormProps) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  useEffect(() => {
    onDirtyChange(Object.keys(dirtyFields).length > 0);
  }, [dirtyFields, onDirtyChange]);

  return (
    <form className="profile-modal__holder" onSubmit={handleSubmit(onSubmit)}>
      <div className="profile-modal__header">
        {data ? (
          <span className="profile-modal__header-title">{t("AuthorEdit")}</span>
        ) : (
          <>
            <span className="profile-modal__header-title">
              {t("AddAuthor")}
            </span>
            <p className="profile-modal__header-text">
              {t("AuthorDescription")}
            </p>
          </>
        )}
      </div>
      <div className="form profile-modal__form">
        <div className="form__block">
          <span className="label">{t("Initials")}*</span>
          <Input
            {...register("initials", {
              ...requiredValidateWhiteSpaces(t("PasteInitials")),
            })}
            isInvalid={!!errors.initials}
            errorText={errors.initials?.message}
            maxLength={2}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("Avatar")}</span>
          <div className="profile-modal__avatar">
            <Controller
              control={control}
              render={({ field: { onChange, value } }) => (
                <ImageUploader
                  onChange={onChange}
                  value={value}
                  folder="authors"
                  isInvalid={!!errors.avatar}
                />
              )}
              name="avatar"
            />
          </div>
        </div>
        <div className="form__block">
          <span className="label">{t("FirstName")}*</span>
          <Input
            {...register("name", {
              ...requiredValidateWhiteSpaces(t("PasteFirstName")),
            })}
            isInvalid={!!errors.name}
            errorText={errors.name?.message}
          />
        </div>
        <div className="form__block">
          <span className="label">{t("Company")}</span>
          <Input {...register("company")} />
        </div>
        <div className="form__block">
          <span className="label">{t("Description")}</span>
          <Input {...register("description")} />
        </div>
        <button className="btn btn-fond scale-up lighten-up btn-full blue">
          <i className="btn__text">
            {isLoading ? t("Saving") : data ? t("Save") : t("AddAuthor")}
          </i>
        </button>
      </div>
    </form>
  );
};
