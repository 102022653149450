import { UserStore } from "@/app/providers/MobxStore";
import ImageUploader from "@/components/ImageUploader";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { SelectCategoryController } from "@/features/SelectCategoryController";
import { notify } from "@/shared/lib/utils/notify";
import { requiredValidateWhiteSpaces } from "@/shared/lib/utils/requiredValidateWhiteSpaces";
import { Input, Textarea } from "@/shared/ui/Form";
import {
  IExpert,
  useAddExpertMutation,
  useEditExpertMutation,
} from "@op/entities";
import classNames from "classnames";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type TFormFields = Omit<IExpert, "id" | "category"> & {};

export const defaultValues: TFormFields = {
  index: "",
  name: "",
  license_ru: "",
  license_eu: "",
  image: "",
  text: "",
  order: 0,
  position: "",
  education: "",
  experience: 0,
  categories: [],
};

interface IProps extends IModalOpen {
  data?: Nullable<IExpert>;
  onSave?: () => void;
}

const ExpertsModal = ({ open, onClose, data, onSave }: IProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [addExpert, { isLoading: isAddExpertLoading }] = useAddExpertMutation();
  const [editExpert, { isLoading: isEditExpertLoading }] =
    useEditExpertMutation();

  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors, dirtyFields },
  } = useForm({
    defaultValues: data || defaultValues,
    mode: "onSubmit",
  });

  const handleSave = async (formData: TFormFields) => {
    const requestData = {
      ...formData,
      token: UserStore.token!,
    };

    const response = data
      ? await editExpert(requestData)
      : await addExpert(requestData);

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
      return;
    }

    if ("data" in response) {
      onSave?.();
      onClose();
      reset(defaultValues);
    }
  };

  const handleClose = () => {
    if (Object.keys(dirtyFields).length > 0) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
      reset(data || defaultValues);
    }
  }, [open, data, reset]);

  useEffect(() => {
    reset(data || defaultValues);
  }, [data, reset]);

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <form
          className="profile-modal__holder"
          onSubmit={handleSubmit(handleSave)}
        >
          <div className="profile-modal__header">
            {data ? (
              <span className="profile-modal__header-title">
                {t("ExpertsEdit")}
              </span>
            ) : (
              <>
                <span className="profile-modal__header-title">
                  {t("ExpertsAdd")}
                </span>
              </>
            )}
          </div>
          <div className="form profile-modal__form">
            <div className="form__block">
              <span className="label">{t("Category")}*</span>
              <SelectCategoryController
                name="categories"
                control={control}
                errors={errors}
                multiple
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsIndex")}*</span>
              <Input
                {...register("index", {
                  ...requiredValidateWhiteSpaces(t("PasteIdentifier")),
                  pattern: {
                    value: /^([a-zA-Z0-9_-]+)$/,
                    message: t("OnlyLatin"),
                  },
                })}
                isInvalid={!!errors.index}
                errorText={errors.index?.message}
                disabled={!!data}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("FullName")}*</span>
              <Input
                {...register("name", {
                  ...requiredValidateWhiteSpaces(t("PasteFullName")),
                })}
                isInvalid={!!errors.name}
                errorText={errors.name?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsLicenseRu")}*</span>
              <Input
                rows={5}
                {...register("license_ru", {
                  ...requiredValidateWhiteSpaces(t("PasteExpertsLicenseRu")),
                })}
                isInvalid={!!errors.license_ru}
                errorText={errors.license_ru?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsLicenseEu")}</span>
              <Input
                rows={5}
                {...register("license_eu")}
                isInvalid={!!errors.license_eu}
                errorText={errors.license_eu?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsText")}*</span>
              <Textarea
                rows={6}
                {...register("text", {
                  ...requiredValidateWhiteSpaces(t("PasteExpertsText")),
                  maxLength: {
                    value: 5000,
                    message: t("ExpertsTextMaxLength", {
                      maxLength: 5000,
                    }),
                  },
                })}
                isInvalid={!!errors.text}
                errorText={errors.text?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsPosition")}*</span>
              <Input
                rows={5}
                {...register("position", {
                  ...requiredValidateWhiteSpaces(t("PasteExpertsPosition")),
                })}
                isInvalid={!!errors.position}
                errorText={errors.position?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsExperience")}</span>
              <Input
                {...register("experience", {
                  validate: {
                    checkValidValue: (value: number) =>
                      value > 1970 || t("InvalidExpertsExperience"),
                  },
                })}
                type="number"
                isInvalid={!!errors.experience}
                errorText={errors.experience?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsOrder")}*</span>
              <Input
                {...register("order", {
                  required: t("PasteExpertsOrder"),
                })}
                type="number"
                isInvalid={!!errors.order}
                errorText={errors.order?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsEducation")}*</span>
              <Input
                {...register("education")}
                isInvalid={!!errors.education}
                errorText={errors.education?.message}
              />
            </div>
            <div className="form__block">
              <span className="label">{t("ExpertsImage")}*</span>
              <div className="profile-modal__image">
                <Controller
                  control={control}
                  rules={{ required: t("PasteExpertsImage") }}
                  render={({ field: { onChange, value } }) => (
                    <ImageUploader
                      onChange={onChange}
                      value={value}
                      folder="experts"
                      isInvalid={!!errors.image}
                    />
                  )}
                  name="image"
                />
              </div>
              {errors.image && (
                <p className="input-error" style={{ display: "block" }}>
                  {errors.image?.message}
                </p>
              )}
            </div>
            <button
              className={classNames(
                "btn btn-fond scale-up lighten-up btn-full blue",
                Object.keys(errors).length > 0 && "btn-disabled"
              )}
              type="submit"
            >
              {data ? (
                <i className="btn__text">
                  {isEditExpertLoading || isAddExpertLoading
                    ? t("Saving")
                    : t("Save")}
                </i>
              ) : (
                <i className="btn__text">
                  {isAddExpertLoading || isEditExpertLoading
                    ? t("Saving")
                    : t("ExpertsAdd")}
                </i>
              )}
            </button>
          </div>
        </form>
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default observer(ExpertsModal);
