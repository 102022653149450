import UserStore from "@/app/providers/MobxStore/model/UserStore";
import { ConfirmCloseModal, Modal } from "@/features/Modal";
import { notify } from "@/shared/lib/utils/notify";
import {
  IAuthor,
  useAddAuthorMutation,
  useEditAuthorMutation,
  useGetAuthorList,
} from "@op/entities";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { AuthorModalForm, TFormFields } from "./AuthorModalForm";

interface IModalProps extends IModalOpen {
  data: Nullable<IAuthor>;
  onSave?: () => void;
}

const AuthorModal = ({ open, onClose, data, onSave }: IModalProps) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const { data: authorsData } = useGetAuthorList();
  const authors = authorsData?.payload;
  const [editAuthor, { isLoading: isEditing }] = useEditAuthorMutation();
  const [addAuthor, { isLoading: isAdding }] = useAddAuthorMutation();
  const [isDirty, setIsDirty] = useState(false);

  const handleSave = async (formData: TFormFields) => {
    const lastAuthorId =
      authors?.reduce(
        (maxId, author) => (maxId = Math.max(author.author_id, maxId)),
        0
      ) || 0;

    const response = data
      ? await editAuthor({
          ...formData,
          author_id: data.author_id,
          token: UserStore.token!,
        })
      : await addAuthor({
          ...formData,
          author_id: lastAuthorId + 1,
          token: UserStore.token!,
        });

    if ("error" in response) {
      notify(t("AnErrorOcurred"));
    } else {
      onSave?.();
      onClose();
    }
  };

  const handleClose = () => {
    if (isDirty) {
      setConfirmModalOpen(true);
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (!open) {
      setConfirmModalOpen(false);
    }
  }, [open]);

  const isLoading = isEditing || isAdding;

  return (
    <Modal open={open} onClose={handleClose} className="profile-modal">
      <>
        <AuthorModalForm
          data={data}
          isLoading={isLoading}
          onSubmit={handleSave}
          onDirtyChange={setIsDirty}
        />
        <ConfirmCloseModal
          open={confirmModalOpen}
          onClose={() => setConfirmModalOpen(false)}
          onConfirm={onClose}
        />
      </>
    </Modal>
  );
};

export default AuthorModal;
